
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonLabel, IonChip, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, modalController, IonFab, IonFabButton, IonIcon, IonSkeletonText } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';
import Modal from './createBid.vue';
import Raffle from './signUpToBuy.vue';
import { createOutline, createSharp, bugOutline, bugSharp, ellipsisHorizontalCircleOutline, ellipsisHorizontalCircleSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'ShowInserat',
  data () {
    return {
      inserat: null,
      finishedLoading: false,
      websocketConnection: null as any,
      userIsInRaffle: false,
      addIconIos: createOutline,
      addIconMd: createSharp,
      userIsAuthenticated: false,
      userIsOwner: false,
      errorIconIos: bugOutline,
      errorIconMd: bugSharp,
      donationIconIos: ellipsisHorizontalCircleOutline,
      donationIconMd: ellipsisHorizontalCircleSharp,
      priceIsLoading: false,
      highestBid: 0,
      isDarkMode: false,
      cardBackgroundStyle: {
        backgroundColor: 'white',
        marginTop: "-70px",
        zIndex: "20",
        transform: "rotate(0deg)",
        minHeight: "60%",
      }
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonLabel,
    IonChip,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonFab,
    IonFabButton,
    IonIcon,
    IonSkeletonText
  },
  created() {



    // check for display mode
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      this.cardBackgroundStyle = {
        backgroundColor: '#262626',
        marginTop: "-70px",
        zIndex: "20",
        transform: "rotate(0deg)",
        minHeight: "60%",
      }
    }

    if (localStorage.userId && localStorage.userToken){
        this.userIsAuthenticated = true;
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id)
        .then(response => {
          this.inserat = response.data;

          // check if user entered raffle
          if (this.inserat.inseratType == "directbuy" && localStorage.userId && localStorage.userToken){

            const formData = new FormData();
            formData.append("inserat", this.inserat.uuid);
            axios.post(process.env.VUE_APP_API_URL +'/check-raffle', formData,{
              headers: {
                'Authorization': `Bearer ` + localStorage.userToken
              }
            })
                .then(response => {
                  this.userIsInRaffle = response.data.userIsInRaffle === true;
                })
                .finally(() => {
                  this.finishedLoading = true;
                });
          } else {
            this.finishedLoading = true;
          }

          if (this.inserat.inseratType == "auction"){
              // get highest and latest bid
            this.priceIsLoading = true;
              const formData = new FormData();
              formData.append("inserat", this.$route.params.id);
              axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-highest-bid', formData).then(response => {
                  this.inserat.price = response.data.highestBid;
                  this.highestBid = response.data.highestBid;
                  this.priceIsLoading = false;
              });
          }

          const formData = new FormData();
          formData.append("inserat", this.inserat.uuid);
          axios.post(process.env.VUE_APP_API_URL +'/inserat-increment', formData);

        })
        .catch(error => {
          console.log(error);
        });


    // check user ownership
    if (localStorage.userId && localStorage.userToken){

      const formData = new FormData();
      formData.append("inserat", this.$route.params.id);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-owner', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
      .then(result => {
          if (result.data.status){
            this.userIsOwner = true;
          }
      });
    }


    //// events
    const u = new URL(process.env.VUE_APP_API_URL + '/.well-known/mercure');
    u.searchParams.append('topic', process.env.VUE_APP_API_URL + '/inserats/'+this.$route.params.id);

    const es = new EventSource(u.toString());
    es.onmessage = e => {
      this.inserat = JSON.parse(e.data);
    }

    /// listen for new bids when inserat is an auction

      const bidU = new URL(process.env.VUE_APP_API_URL + '/.well-known/mercure');
      bidU.searchParams.append('topic', process.env.VUE_APP_API_URL + '/inserats/'+this.$route.params.id + '/bids');

      const bidEs = new EventSource(bidU.toString());
      bidEs.onmessage = e => {
        console.log("New Bid", e);
        this.priceIsLoading = true;
        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);
        axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-highest-bid', formData).then(response => {
          this.inserat.price = response.data.highestBid;
          this.priceIsLoading = false;
        });
      }


    //// display mode events
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      console.log("Switched Display Mode");
      if (e.matches){
        this.isDarkMode = true;
        this.cardBackgroundStyle = {
          backgroundColor: '#262626',
          marginTop: "-70px",
          zIndex: "20",
          transform: "rotate(0deg)",
          minHeight: "60%",
        }
      } else {
        this.isDarkMode = false;
        this.cardBackgroundStyle = {
          backgroundColor: 'white',
          marginTop: "-70px",
          zIndex: "20",
          transform: "rotate(0deg)",
          minHeight: "60%",
        }
      }
    });

  },
  methods: {
    editInserat() {
        this.$router.push({ path: '/inserat/' + this.$route.params.id + '/edit'});
    },
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    async openModal() {
      const modal = await modalController
          .create({
            component: Modal,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Buy Inserat',
              inserat: this.inserat,
              highestBid: this.highestBid,
            },
          })
      return modal.present();
    },
    async openRaffle() {
      const modal = await modalController
          .create({
            component: Raffle,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Enter Raffle',
              inserat: this.inserat
            },
          })
      return modal.present();
    },
  }
});

