<template>
  <ion-page>
    <ion-header :translucent="true" class="ion-no-border">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-menu-button color="primary"></ion-menu-button>
        </ion-buttons>
        <ion-title></ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true" v-if="finishedLoading && inserat && (inserat.isActive === true || userIsOwner)" class="noScroll">

      <!-- fab placed to the top end -->
      <ion-fab vertical="top" horizontal="end" slot="fixed" v-if="userIsOwner">
        <ion-fab-button @click="editInserat">
          <ion-icon :ios="addIconIos" :md="addIconMd"></ion-icon>
        </ion-fab-button>
      </ion-fab>

      <div style="z-index: 1;">
        <img :src="inserat.inseratImages[0].accessUrl" style="z-index: 1;">
      </div>

      <div class="onlyTopCornersRounded" :style="cardBackgroundStyle" style="margin-top: -70px; z-index: 20;transform: rotate(0deg); min-height: calc(70% + 70px);">
        <ion-row class="sidePaddings topPaddings">

          <ion-col size="12">
            <ion-chip v-if="inserat.isDonation" style="width: 100%; text-align: center; justify-content: center;">
              <ion-label>Erlös wird gespendet</ion-label>
              <ion-icon :ios="donationIconIos" :md="donationIconMd" style="width: 14px; height: 14px; color: #262626;" v-if="isDarkMode"></ion-icon>
              <ion-icon :ios="donationIconIos" :md="donationIconMd" style="width: 14px; height: 14px; color: white;" v-if="!isDarkMode"></ion-icon>
            </ion-chip>
            <ion-chip v-if="inserat.inseratType === 'gift'" style="width: 100%; text-align: center; justify-content: center;">
              <ion-label>Zu Verschenken</ion-label>
            </ion-chip>
          </ion-col>

          <ion-col size="12">
            <br>
            <ion-label class="title" >{{ inserat.title }}</ion-label>
            <br>
            <ion-label class="subtitle" >Wird verkauft von @{{ inserat.createdBy.username }}</ion-label>
          </ion-col>
          <ion-col size="12">
            <br>
            <ion-label style="font-weight: bold; font-size: 18px; color: grey;" >Beschreibung</ion-label>
            <br>
            <div style="white-space: break-spaces;">
              <ion-label class="ion-text-wrap" >{{ inserat.description }}</ion-label>
            </div>
            <br>
            <ion-label class="ion-text-wrap"  v-if="inserat.inseratType === 'auction'">Auktion beginnt in: </ion-label>
          </ion-col>
          <ion-col size="6" v-if="inserat.inseratType !== 'gift'">
            <br>
            <ion-label class="price" v-if="!priceIsLoading && inserat.inseratType === 'auction'" ><span style="font-weight: bold; font-size: 46px;">{{ highestBid }}{{ inserat.currency }}</span></ion-label>
            <ion-label class="price" v-if="!priceIsLoading && inserat.inseratType === 'directbuy'" ><span style="font-weight: bold; font-size: 46px;">{{ inserat.price }}{{ inserat.currency }}</span></ion-label>
            <ion-skeleton-text v-if="priceIsLoading" animated></ion-skeleton-text>
            <br><br>
          </ion-col>
          <ion-col size="6" v-if="!userIsOwner && userIsAuthenticated">
            <br>
            <ion-button shape="round" v-if="inserat.inseratType === 'directbuy' && !userIsInRaffle" @click="openRaffle">Jetzt kaufen</ion-button>
            <ion-button shape="round" v-if="inserat.inseratType === 'gift' && !userIsInRaffle" @click="openRaffle">Jetzt Teilnehmen</ion-button>
            <ion-button shape="round" v-if="inserat.inseratType === 'directbuy' && userIsInRaffle" color="secondary">Eingeschrieben</ion-button>
            <ion-button shape="round" v-if="inserat.inseratType === 'auction'" @click="openModal">Jetzt bieten</ion-button>
            <br><br>
          </ion-col>
          <ion-col size="6" v-if="!userIsOwner && !userIsAuthenticated">
            <br>
            <ion-button shape="round" @click="openModal">Anmelden</ion-button>
          </ion-col>
          <ion-col size="6" v-if="userIsOwner && inserat.inseratType !== 'gift'">
            <br>
            <ion-button shape="round">Dein Inserat</ion-button>
          </ion-col>
          <ion-col size="12" v-if="userIsOwner && inserat.inseratType === 'gift'">
            <br>
            <ion-button shape="round" style="width: 100%;">Dein Inserat</ion-button>
          </ion-col>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>
                <ion-row>
                  <ion-col size="3">
                    <img class="rounded-image imageCircle" src="http://via.placeholder.com/50x50.png?text=Avatar" alt="test" />
                  </ion-col>
                  <ion-col size="1"></ion-col>
                  <ion-col size="8">
                    <ion-card-subtitle>{{ inserat.createdBy.firstname }} {{ inserat.createdBy.lastname }}</ion-card-subtitle>
                    <ion-card-title>{{ inserat.createdBy.username }}</ion-card-title>
                  </ion-col>
                </ion-row>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>
    <ion-content v-if="finishedLoading && inserat && (inserat.isActive === false && !userIsOwner)" :fullscreen="true">
      <div class="container sideMargins">
        <ion-icon :ios="errorIconIos" :md="errorIconMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
        <strong class="capitalize">Inserat nicht mehr verfügbar.</strong>
        <p>Unser Team kümmert sich bereits um die Ursache.</p>
        <ion-button size="large" class="topMargins sideMargins" href="/all" expand="block" color="primary">Alles klar</ion-button>
      </div>
    </ion-content>

    <!-- Skeleton Dummy -->
    <ion-content :fullscreen="true" v-if="!finishedLoading" class="noScroll">

      <div style="z-index: 1;">
        <ion-skeleton-text animated style="line-height: 300px; margin-top: 0px; z-index: 1;"></ion-skeleton-text>
      </div>

      <div class="onlyTopCornersRounded" :style="cardBackgroundStyle" style="margin-top: -70px; z-index: 20;transform: rotate(0deg); min-height: calc(70% + 70px);">
        <ion-row class="sidePaddings topPaddingsDummy">

          <ion-col size="12">
            <ion-chip style="width: 100%; text-align: center; justify-content: center;">
              <ion-label><ion-skeleton-text animated style="width: 200px;"></ion-skeleton-text></ion-label>
            </ion-chip>
          </ion-col>

          <ion-col size="12">
            <br>
            <ion-label class="title" ><ion-skeleton-text animated style="line-height: 40px;"></ion-skeleton-text></ion-label>
            <br>
            <ion-label class="subtitle"><ion-skeleton-text animated style="line-height: 20px;"></ion-skeleton-text></ion-label>
          </ion-col>
          <ion-col size="12">
            <br>
            <ion-label style="font-weight: bold; font-size: 18px; color: grey;" >Beschreibung</ion-label>
            <br>
            <div style="white-space: break-spaces;">
              <ion-label class="ion-text-wrap" ><ion-skeleton-text animated style="line-height: 30px;"></ion-skeleton-text></ion-label>
            </div>
          </ion-col>
          <ion-col size="6">
            <br>
            <ion-skeleton-text animated style="line-height: 40px;"></ion-skeleton-text>
            <br><br>
          </ion-col>
          <ion-col size="6">
            <br>
            <ion-skeleton-text animated style="line-height: 40px; width: 150px;"></ion-skeleton-text>
            <br><br>
          </ion-col>
          <ion-col size="12">
            <ion-card>
              <ion-card-header>
                <ion-row>
                  <ion-col size="3">
                    <img class="rounded-image imageCircle" src="http://via.placeholder.com/50x50.png?text=Avatar" alt="test" />
                  </ion-col>
                  <ion-col size="1"></ion-col>
                  <ion-col size="8">
                    <ion-card-subtitle><ion-skeleton-text animated style="line-height: 20px; width: 150px;"></ion-skeleton-text></ion-card-subtitle>
                    <ion-card-title><ion-skeleton-text animated style="line-height: 20px; width: 100px;"></ion-skeleton-text></ion-card-title>
                  </ion-col>
                </ion-row>
              </ion-card-header>
            </ion-card>
          </ion-col>
        </ion-row>
      </div>
    </ion-content>


  </ion-page>
</template>

<script lang="ts">
import { IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar, IonRow, IonCol, IonLabel, IonChip, IonButton, IonCard, IonCardHeader, IonCardTitle, IonCardSubtitle, modalController, IonFab, IonFabButton, IonIcon, IonSkeletonText } from '@ionic/vue';
import axios from 'axios';
import { defineComponent, ref } from 'vue';
import moment from 'moment';
import Modal from './createBid.vue';
import Raffle from './signUpToBuy.vue';
import { createOutline, createSharp, bugOutline, bugSharp, ellipsisHorizontalCircleOutline, ellipsisHorizontalCircleSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'ShowInserat',
  data () {
    return {
      inserat: null,
      finishedLoading: false,
      websocketConnection: null as any,
      userIsInRaffle: false,
      addIconIos: createOutline,
      addIconMd: createSharp,
      userIsAuthenticated: false,
      userIsOwner: false,
      errorIconIos: bugOutline,
      errorIconMd: bugSharp,
      donationIconIos: ellipsisHorizontalCircleOutline,
      donationIconMd: ellipsisHorizontalCircleSharp,
      priceIsLoading: false,
      highestBid: 0,
      isDarkMode: false,
      cardBackgroundStyle: {
        backgroundColor: 'white',
        marginTop: "-70px",
        zIndex: "20",
        transform: "rotate(0deg)",
        minHeight: "60%",
      }
    }
  },
  components: {
    IonButtons,
    IonContent,
    IonHeader,
    IonMenuButton,
    IonPage,
    IonTitle,
    IonToolbar,
    IonRow,
    IonCol,
    IonLabel,
    IonChip,
    IonButton,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonFab,
    IonFabButton,
    IonIcon,
    IonSkeletonText
  },
  created() {



    // check for display mode
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      // dark mode
      this.cardBackgroundStyle = {
        backgroundColor: '#262626',
        marginTop: "-70px",
        zIndex: "20",
        transform: "rotate(0deg)",
        minHeight: "60%",
      }
    }

    if (localStorage.userId && localStorage.userToken){
        this.userIsAuthenticated = true;
    }

    // get inserat data
    axios.get(process.env.VUE_APP_API_URL + '/inserats/' + this.$route.params.id)
        .then(response => {
          this.inserat = response.data;

          // check if user entered raffle
          if (this.inserat.inseratType == "directbuy" && localStorage.userId && localStorage.userToken){

            const formData = new FormData();
            formData.append("inserat", this.inserat.uuid);
            axios.post(process.env.VUE_APP_API_URL +'/check-raffle', formData,{
              headers: {
                'Authorization': `Bearer ` + localStorage.userToken
              }
            })
                .then(response => {
                  this.userIsInRaffle = response.data.userIsInRaffle === true;
                })
                .finally(() => {
                  this.finishedLoading = true;
                });
          } else {
            this.finishedLoading = true;
          }

          if (this.inserat.inseratType == "auction"){
              // get highest and latest bid
            this.priceIsLoading = true;
              const formData = new FormData();
              formData.append("inserat", this.$route.params.id);
              axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-highest-bid', formData).then(response => {
                  this.inserat.price = response.data.highestBid;
                  this.highestBid = response.data.highestBid;
                  this.priceIsLoading = false;
              });
          }

          const formData = new FormData();
          formData.append("inserat", this.inserat.uuid);
          axios.post(process.env.VUE_APP_API_URL +'/inserat-increment', formData);

        })
        .catch(error => {
          console.log(error);
        });


    // check user ownership
    if (localStorage.userId && localStorage.userToken){

      const formData = new FormData();
      formData.append("inserat", this.$route.params.id);

      axios.post(process.env.VUE_APP_API_URL + '/inserat-owner', formData, {
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
      .then(result => {
          if (result.data.status){
            this.userIsOwner = true;
          }
      });
    }


    //// events
    const u = new URL(process.env.VUE_APP_API_URL + '/.well-known/mercure');
    u.searchParams.append('topic', process.env.VUE_APP_API_URL + '/inserats/'+this.$route.params.id);

    const es = new EventSource(u.toString());
    es.onmessage = e => {
      this.inserat = JSON.parse(e.data);
    }

    /// listen for new bids when inserat is an auction

      const bidU = new URL(process.env.VUE_APP_API_URL + '/.well-known/mercure');
      bidU.searchParams.append('topic', process.env.VUE_APP_API_URL + '/inserats/'+this.$route.params.id + '/bids');

      const bidEs = new EventSource(bidU.toString());
      bidEs.onmessage = e => {
        console.log("New Bid", e);
        this.priceIsLoading = true;
        const formData = new FormData();
        formData.append("inserat", this.$route.params.id);
        axios.post(process.env.VUE_APP_API_URL + '/inserat-auction-highest-bid', formData).then(response => {
          this.inserat.price = response.data.highestBid;
          this.priceIsLoading = false;
        });
      }


    //// display mode events
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
      console.log("Switched Display Mode");
      if (e.matches){
        this.isDarkMode = true;
        this.cardBackgroundStyle = {
          backgroundColor: '#262626',
          marginTop: "-70px",
          zIndex: "20",
          transform: "rotate(0deg)",
          minHeight: "60%",
        }
      } else {
        this.isDarkMode = false;
        this.cardBackgroundStyle = {
          backgroundColor: 'white',
          marginTop: "-70px",
          zIndex: "20",
          transform: "rotate(0deg)",
          minHeight: "60%",
        }
      }
    });

  },
  methods: {
    editInserat() {
        this.$router.push({ path: '/inserat/' + this.$route.params.id + '/edit'});
    },
    convertCreatedAt(date: string){
      return moment(date).format("DD.MM.Y");
    },
    convertCreatedAtTime(date: string){
      return moment(date).format("HH:MM");
    },
    async openModal() {
      const modal = await modalController
          .create({
            component: Modal,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Buy Inserat',
              inserat: this.inserat,
              highestBid: this.highestBid,
            },
          })
      return modal.present();
    },
    async openRaffle() {
      const modal = await modalController
          .create({
            component: Raffle,
            cssClass: 'my-custom-class',
            componentProps: {
              title: 'Enter Raffle',
              inserat: this.inserat
            },
          })
      return modal.present();
    },
  }
});

</script>

<style scoped>

  .container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .container strong {
    font-size: 20px;
    line-height: 26px;
  }

  .container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  .container a {
    text-decoration: none;
  }

  .labelContrast {
      color: var(--ion-color-primary-contrast, #fff)
  }

  .imageCircle {
    border: 2px solid #1dc0c6;
  }

  .textMarginBottom {
    margin-bottom: 25px;
  }

  .rounded-image {
    border-radius: 50%;
  }

  .sideMargins {
    margin-left: 25px;
    margin-right: 25px;
  }

  .topMargins {
    margin-top: 25px;
  }

  .sidePaddings {
    padding-left: 25px;
    padding-right: 25px;
  }

  .topPaddings {
    padding-top: 25px;
  }

  .topPaddingsDummy {
    padding-top: 10px;
  }

  .bottomMargins {
    margin-bottom: 25px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .price {
    font-size: 20px;
    font-weight: bold;
  }

  .roundedCorners {
    border-radius: 10px;
  }

  .onlyTopCornersRounded {
    border-radius: 25px 25px 0 0;
  }

  .onlyBottomCornersRounded {
    border-radius: 0 0 25px 25px;
  }

  .noScroll {
    overflow: hidden;
  }

</style>
