<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="tertiary" @click="close()">Schließen</ion-button>
      </ion-buttons>
      <ion-title>{{ type }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" v-if="!enteredRaffle && userIsAuthenticated">

    <div style="z-index: 1;" class="imageContainer" v-if="!isLoading && !enteredRaffle">
      <img :src="inserat.inseratImages[0].accessUrl" style="z-index: 1; border-radius: 2%; opacity: 0.2;">
      <div class="imageTextCentered" v-if="inserat.inseratType === 'directbuy' || inserat.inseratType === 'gift'">
        <strong class="capitalize">{{ inserat.title }}</strong>
        von {{ inserat.createdBy.username }}
        für {{ inserat.price }}{{ inserat.currency }} kaufen
        <br><br>
        <small>Damit alle eine faire Chance haben, kann nur ein glücklicher Teilnehmer dieses Inserat erwerben.
          Zum Teilnahmeschluss am {{ inserat.raffleEndAt }} wird ein Gewinner zufällig ausgewählt.</small>
      </div>
    </div>

    <ion-row v-if="!isLoading && !enteredRaffle">
      <ion-col size="12" v-if="inserat.inseratType === 'directbuy' || inserat.inseratType === 'gift'">
        <ion-button shape="round" style="width: 100%;" v-if="!userIsInRaffle" @click="enterRaffle()">Teilnehmen</ion-button>
        <p style="text-align: center;"><small v-if="!userIsInRaffle && inserat.inseratType !== 'gift'">Mit der Teilnahme, verpflichtest du dich zum Kauf, wenn du gewinnen solltest.</small></p>
        <ion-button shape="round" style="width: 100%;" v-if="userIsInRaffle">Du nimmst bereits teil</ion-button>
      </ion-col>
      <ion-col size="12" v-if="inserat.inseratType === 'auction'">
        <ion-label class="title">Etwas ist schiefgelaufen</ion-label>
      </ion-col>
    </ion-row>

  </ion-content>

  <ion-content :fullscreen="true" v-if="enteredRaffle && userIsAuthenticated" class="sideMargins">
    <div class="container">
      <strong class="capitalize">Erfolgreich teilgenommen</strong>
      <p style="margin-top: 10px;">Du bekommst bescheid, <br>wenn du der auserwählte bist.</p>

      <ion-button size="large" class="topMargins sideMargins" style="margin-top: 20px;" expand="block" color="primary" @click="closeAndGoToEntries()">Fertig</ion-button>
    </div>
  </ion-content>

  <ion-content :fullscreen="true" v-if="!userIsAuthenticated" class="sideMargins">
    <div class="container">
      <strong class="capitalize">Nicht angemeldet</strong>
      <p>Du musst dich anmelden um an einer Verlosung teilzunehmen.</p>
      <br><br>

      <ion-button size="large" class="topMargins sideMargins" @click="closeAndLogin()" expand="block" color="primary">Anmelden</ion-button>
    </div>
  </ion-content>

</template>

<script>
import { IonContent, IonHeader, IonRow, IonCol, IonTitle, IonLabel, IonToolbar, IonButtons, IonButton, modalController } from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from "axios";

export default defineComponent({
  name: 'Raffle',
  props: {
    title: { type: String, default: 'Raffle' },
    inserat: { type: Object, default: null },
  },
  data() {
    return {
      content: 'Content',
      type: 'Loading',

      userIsInRaffle: false,
      isLoading: true,
      enteredRaffle: false,
      userIsAuthenticated: false,
    }
  },
  methods: {
    closeAndGoToEntries(){
      this.close();
      this.$router.push({ path: '/profile/entries' })
    },
    goToUserEntries(){
        this.$router.push({ path: '/profile/entries' })
    },
    enterRaffle(){

      this.isLoading = true;

      axios.post(process.env.VUE_APP_API_URL + '/inserat_raffle_entries', {
        'inserat': '/inserats/' + this.inserat.uuid
      },{
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      }).then(result => {
          this.enteredRaffle = true;
      }).finally(() => {
        this.isLoading = false;
      });

    },
    async close() {
      await modalController.dismiss();
    },
    async closeAndLogin() {
      await this.$router.push({ path: '/login'});
      await modalController.dismiss();
    }
  },
  created() {

    // check if is auction or direct buy
    if (this.inserat.inseratType === 'auction'){
        this.type = "Etwas ist schiefgelaufen";
    } else {
        this.type = "Sofort Kaufen";
    }



    // check if user is already in the raffle
    if (localStorage.userToken && localStorage.userId){

      this.userIsAuthenticated = true;

      const formData = new FormData();
      formData.append("inserat", this.inserat.uuid);
      axios.post(process.env.VUE_APP_API_URL +'/check-raffle', formData,{
        headers: {
          'Authorization': `Bearer ` + localStorage.userToken
        }
      })
          .then(response => {
            this.userIsInRaffle = response.data.userIsInRaffle === true;
          })
          .finally(() => {
            this.isLoading = false;
          });

    }

  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonLabel, IonRow, IonCol }
});
</script>

<style scoped>

  .imageContainer {
    position: relative;
    text-align: center;
    color: white;
  }

  .imageTextCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .sideMargins {
    margin-left: 25px;
    margin-right: 25px;
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .container {
    text-align: center;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .container strong {
    font-size: 20px;
    line-height: 26px;
  }

  .container p {
    font-size: 16px;
    line-height: 22px;
    color: #8c8c8c;
    margin: 0;
  }

  .container a {
    text-decoration: none;
  }

</style>
