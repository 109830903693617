<template>
  <ion-header>
    <ion-toolbar>
      <ion-buttons slot="start">
        <ion-button color="tertiary" @click="close()">Schließen</ion-button>
      </ion-buttons>
      <ion-title>{{ type }}</ion-title>
    </ion-toolbar>
  </ion-header>
  <ion-content class="ion-padding" v-if="inserat && !submitted">

    <div style="z-index: 1;" class="imageContainer">
      <img :src="inserat.inseratImages[0].accessUrl" alt="thumbnail" style="z-index: 1; border-radius: 2%; opacity: 0.2; height: 200px; width: 100%; object-fit: cover;">

      <div class="imageTextCentered">
        <strong class="capitalize">{{ inserat.title }}</strong>
        von {{ inserat.createdBy.username }}
        <br><br>
      </div>
    </div>

    <ion-card>
      <ion-card-content>
        <ion-card-subtitle>Aktuelles Höchstgebot</ion-card-subtitle>
        <ion-card-title>{{ inserat.price }}{{ inserat.currency }}</ion-card-title>
      </ion-card-content>
    </ion-card>

    <ion-card>
      <ion-card-content>
        <ion-card-subtitle>Gebot erstellen</ion-card-subtitle>
        <small>Preis anklicken zum verändern</small>
        <ion-row>
          <ion-col size="9" style="align-items: center;">
            <ion-input type="number" v-model="bid" style="font-size: 36px;"></ion-input>
          </ion-col>
          <ion-col size="3">
            <p style="font-size: 36px; padding-top: 10px;">{{ inserat.currency }}</p>
          </ion-col>
        </ion-row>
        <ion-button style="width: 100%;" @click="createBid()">Gebot abschicken</ion-button>
      </ion-card-content>
    </ion-card>

  </ion-content>

  <ion-content v-if="submitted" :fullscreen="true">
    <div class="container sideMargins">
      <ion-icon :ios="doneIconIos" :md="doneIconMd" style="width: 100px; height: 100px;"></ion-icon><br><br><br>
      <strong class="capitalize">Dein Gebot wurde abgeschickt.</strong>
      <p>Danke für's bieten.</p>
      <ion-button size="large" class="topMargins sideMargins" @click="close" expand="block" color="primary">Fertig</ion-button>
    </div>
  </ion-content>

</template>

<script>
import {
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  modalController,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonInput,
  IonCardSubtitle,
  toastController,
  IonIcon,
  IonRow, IonCol
} from '@ionic/vue';
import { defineComponent } from 'vue';
import axios from 'axios';
import { checkmarkCircleOutline, checkmarkCircleSharp } from 'ionicons/icons';


export default defineComponent({
  name: 'Modal',
  props: {
    title: { type: String, default: 'Super Modal' },
    inserat: { type: Object, default: null },
    highestBid: { type: Number, default: 0 }
  },
  data() {
    return {
      content: 'Content',
      type: 'Loading',
      bid: 0,
      doneIconIos: checkmarkCircleOutline,
      doneIconMd: checkmarkCircleSharp,
      submitted: false,

    }
  },
  created() {



    // check if is auction or direct buy
    if (this.inserat.inseratType === 'auction'){
        this.type = "Gebot abgeben";
    } else {
        this.type = "Sofort Kaufen";
    }

    // get starting bid
    this.bid = this.inserat.price;

  },
  methods: {
    async close() {
      await modalController.dismiss();
    },
    async openToast(message, duration, color) {

      if (!color){
        color = "primary";
      }

      const toast = await toastController
          .create({
            message: message,
            duration: duration
          })
      return toast.present();
    },
    createBid() {

      // check if bid is integer
      if (!parseInt(this.bid)){
        this.openToast('Überprüfe deine Eingabe.', 3000, 'danger')
        return;
      }

      // check if new bid is more than old bid
      if (this.bid > this.inserat.price){
        // create bid
        axios.post(process.env.VUE_APP_API_URL + '/inserat_bids', {
          'inserat': '/inserats/' + this.inserat.uuid,
          'amount': parseInt(this.bid),
          'currency': this.inserat.currency
        }, {
          headers: {
            'Authorization': `Bearer ` + localStorage.userToken
          }
        }).then(response => {
          console.log(response);
          this.submitted = true;
        }).finally(() => {
          console.log("Finalized.");
        })
      } else {
        this.openToast('Dein Gebot muss größer als das aktuell Höchstbietende Gebot sein.', 3000, 'danger')
      }
    }
  },
  components: { IonContent, IonHeader, IonTitle, IonToolbar, IonButtons, IonButton, IonCard, IonCardContent, IonCardTitle, IonInput, IonCardSubtitle, IonIcon, IonRow, IonCol }
});
</script>

<style scoped>

.container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.container strong {
  font-size: 20px;
  line-height: 26px;
}

.container p {
  font-size: 16px;
  line-height: 22px;
  color: #8c8c8c;
  margin: 0;
}

.container a {
  text-decoration: none;
}

  .imageContainer {
    position: relative;
    text-align: center;
    color: white;
  }

  .imageTextCentered {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    font-size: 24px;
    font-weight: bold;
  }

  .item.item-trns {
    background: transparent;
  }

</style>